export const environment = {
  version_test_web: "0.0.0",
  version_prod_web: "0.0.17",
  config: {
    iconMode: 'ios',
    mode: 'ios',
    autoFocusAssist: false,
    menuType: 'overlay'
  },
  // Set language to use.
  language: 'en',
  firebase: {
    apiKey: "AIzaSyBK2IRRqZTjlQf_cRJu9Y1cW3s4ZFkoQGI",
    authDomain: "itwc-9657a.firebaseapp.com",
    databaseURL: "https://itwc-9657a.firebaseio.com",
    projectId: "itwc-9657a",
    storageBucket: "itwc-9657a.appspot.com",
    messagingSenderId: "557772790841",
    appId: "1:557772790841:web:a3d7e7236cc54b1db541e8"
  },
  production: true,
  // Firebase Cloud Messaging Server Key.
  // Get your gcmKey on https://console.firebase.google.com, under Overview -> Project Settings -> Cloud Messaging.
  // This is needed to send push notifications.
  gcmKey: 'AAAAgd3ZXDk:APA91bHSQx3FncVJyblQ__FsK0AQbLMxKLutJgBkKpQkpfhCzVQqooGJPvtKeUIgLQ_e50AbrfjZF2RiRGea55HD3AJuguGObF8pXvuN1RW-kYfp4EJ51357jCf1DAUE3HwlSzniAHnn',
  // firebase: {
  //   apiKey: "AIzaSyAbCLmQ7dNWPdMtZHojNgudIl8GtEK_YW8",
  //   authDomain: "yelou-dev.firebaseapp.com",
  //   projectId: "yelou-dev",
  //   storageBucket: "yelou-dev.appspot.com",
  //   messagingSenderId: "587417785608",
  //   appId: "1:587417785608:web:a44e112f2e9a988719c8bd",
  //   measurementId: "G-5MTY4KZX6E"
  // },
  // production: false,
  // gcmKey: 'AAAAiMTUFQg:APA91bHhH4hbR-UazczrRgPhM7uXecWUpmpbf527wVv9HPnERWaTP5s_qeCxvCT41JtQWz4tKFm5UcXCEjnjfQuJ1X8VbuLAtHNI3CcD9TnR1Xw3nftnPHNr3L41TJwaWJlwjEqp3r9E',

  // You can find your googleWebClientId on your Firebase app console -> Authentication -> Sign-in Method -> Google -> Web client ID
  googleWebClientId: '557772790841-ua99eq4ttib987h4obt4aqjan5osjnqn.apps.googleusercontent.com',
  // Google Maps JavaScript API Key
  googleMapsKey: 'AIzaSyBK2IRRqZTjlQf_cRJu9Y1cW3s4ZFkoQGI',
};