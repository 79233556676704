import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'pages',
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menus"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/orders.module').then(m => m.OrdersModule),
  },
  {
    path: 'order-show/:id',
    loadChildren: () => import('../../components/order-show/order-show.module').then(m => m.OrderShowModule),
    data: {
      breadcrumb: "Pagos"
    }
  },
  {
    path: 'payments',
    loadChildren: () => import('../../components/payments/payments.module').then(m => m.PaymentsModule),
  },
  {
    path: 'stores',
    loadChildren: () => import('../../components/stores/stores.module').then(m => m.StoresModule),
  },
  {
    path: 'affiliates',
    loadChildren: () => import('../../components/affiliates/affiliates.module').then(m => m.AffiliatesModule),
  },
  {
    path: 'yets',
    loadChildren: () => import('../../components/yets/yets.module').then(m => m.YetsModule),
  },
  {
    path: 'yet-show/:id',
    loadChildren: () => import('../../components/yet-show/yet-show.module').then(m => m.YetShowModule),
    data: {
      breadcrumb: "Yets"
    }
  },
  {
    path: 'local-show/:id',
    loadChildren: () => import('../../components/local-show/local-show.module').then(m => m.LocalShowModule),
    data: {
      breadcrumb: "Local"
    }
  },
  {
    path: 'clients',
    loadChildren: () => import('../../components/clients/clients.module').then(m => m.ClientsModule),
  },

  {
    path: 'client-show/:id',
    loadChildren: () => import('../../components/client-show/client-show.module').then(m => m.ClientShowModule),
    data: {
      breadcrumb: "Clientes"
    }
  },
  {
    path: 'affiliated-show/:id',
    loadChildren: () => import('../../components/affiliated-show/affiliated-show.module').then(m => m.AffiliatedShowModule),
    data: {
      breadcrumb: "Afiliado"
    }
  },
  {
    path: 'commerces-control/:id',
    loadChildren: () => import('../../components/commerces-control/commerces-control.module').then(m => m.CommercesControlModule),
    data: {
      breadcrumb: "Locales"
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
  {
    path: 'issues',
    loadChildren: () => import('../../components/issues/issues.module').then(m => m.IssuesModule),
  },
  {
    path: 'products/:id',
    loadChildren: () => import('../../components/commerces-control/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Locales",
    }
  },
  {
    path: 'verification',
    loadChildren: () => import('../../components/verification/verification.module').then(m => m.VerificationModule),
  },
  {
    path: 'form-claim',
    loadChildren: () => import('../../components/form-claim/form-claim.module').then(m => m.FormClaimModule),
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  }
];