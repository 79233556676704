import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  downloadURL: Observable<string>;
  profileURL: Promise<any>;

  constructor(
    private afStorage: AngularFireStorage,
  ) { }

  updatePhoto(collectionName, file) {
    return new Promise((resolve, reject) => {
      if(file != ''){
        let n = Date.now();
        let file_path = `${collectionName}/${n}`;
        const fileRef = this.afStorage.ref(file_path);
        const task = this.afStorage.upload(`${collectionName}/${n}`, file);
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe(url => {
                if (url) {
                  let image = url;
                  resolve({ image, file_path });
                } else { reject({}); }
              });
            })
          )
          .subscribe(url => {
            if (url) {
              //resolve(true);
            }
          });
      }else{
        resolve({image: '', file_path:''})
      }
    });
  }

  public updatePhotoUser(collectionName, user_uid, id, file) {
    return new Promise((resolve, reject) => {
      if(file != ''){
        let n = Date.now();
        let file_path = `${collectionName}/${user_uid}/${n}_${id}`;
        // const fileRef = this.afStorage.ref(file_path);
        // console.log("fileRef: ",fileRef);
        this.afStorage.upload(file_path, file).then((snapshot: firebase.storage.UploadTaskSnapshot) => {
          const refe = this.afStorage.storage.ref(file_path);

          refe.getDownloadURL().then((url: string) => {
            this.profileURL = snapshot.ref.getDownloadURL();
            resolve({ image: url, file_path });
          }).catch(err => {
            reject(err);
          });

        })
        .catch(err => {
          reject(err);
        });
      }else{
        resolve({image: '', file_path:''})
      }
    });
  }

  public deletePhoto(collectionName: string, url: string): void {
    console.log(collectionName);
    console.log(url);
    // Get the fileName from the URL.
    const fileName = url.substring(url.lastIndexOf('%2F') + 3, url.lastIndexOf('?'));
    console.log(fileName);

    const filePath = collectionName + '/' + fileName;
    const fileRef = this.afStorage.ref(filePath);

    fileRef.delete().subscribe((res0)=>{
      console.log(res0)
    });
    // // Check if file really exists on Firebase storage.
    // // this.afStorage.ref(filePath).delete();
    // this.afStorage.ref(filePath).child(userId).then(res => {
    //   res.child().delete();
    // });
  };
}
