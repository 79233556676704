export class User {
  public id: string = '';
  public uid: string = '';
  public commerce_uid = '';
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public image: string = '';
  public photoURL: string = '';
  public username: string = '';
  public notifications: boolean = false;
  public is_admin: boolean = false;
  public is_driver: boolean = false;
  public is_client: boolean = false;
  public is_super: boolean = false;
  public pushToken: string = '';
  public country: string = '';
  public deparment: string = '';
  public municipality: string = '';
  public zone: string = '';
  public direction: string = '';
  public phone: string = '';
  public has_delivery_expirience: boolean = false;
  public emailVerified: boolean = false;
  public is_enabled_to_drive: boolean = false;
  public who_receives: string = '';
  public sucursal: string = '';
  public sucursal_uid: string = '';
  public locations_uid:string = '';
  public lat_commerce:string = '';
  public lng_commerce:string = '';
  public lat_delivery_end:string = '';
  public lng_delivery_end:string = '';
  public is_card:boolean = true;
  public is_delivery:boolean = true;
  public manager_commerce_uid: string = '';
  public commerces: Array<{ commerce_uid: string }> = [];
  public created_at: Date = new Date();
  public updated_at: any = null;
  public deleted_at: any = null;

  public is_enabled: boolean = false;
  public register_number: string = '';
  public nit: string = '';
  public membership: string = '';
  public tel: string = '';
  public cel: string = '';
  public driver_limit_money: string = '0';
  public is_gps_on: boolean = false;
  public register_code_yet: string = '';
  public is_new_driver: boolean = false;

  public admin_zone = [];
  public is_admin_zone: boolean = true;
  constructor(){}
}

export class ITimer {
  public seconds: number;
  public secondsRemaining: number;
  public runTimer: boolean;
  public hasStarted: boolean;
  public hasFinished: boolean;
  public displayTime: string;
}
