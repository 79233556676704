import { Component, OnInit, ViewChild } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';
import { User } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/service/auth/auth.service';
import { FirestoreService } from 'src/app/shared/service/firestore/firestore.service';
import { StorageService } from 'src/app/shared/service/storage/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public active = 1;
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  
  imageUrl: string = '../assets/images/others/placeholder.jpg';
  resultImage: any;

  // Plugin configuration
  config = {
    zoomable: false
  };

  //busqueda
  collectionName = 'users';
  collectionField = 'name';
  orderByDir: any = 'desc';
  collectionLimit = 1000;

  user = new User();
  photoURL: string = '';
  displayName: string = '';
  edit:boolean = false;

  constructor(
    public auth: AuthService,  
    private firestore: FirestoreService,
    private storageS: StorageService,
  ) { }

  ngOnInit(): void {
    this.auth.getUser2().then(res0 => {
      // console.log("getUser2: ",res0);
      this.user = res0;
      this.displayName = res0.who_receives!=undefined&&res0.who_receives!=null&&res0.who_receives!='' ? res0.who_receives :'';
      this.photoURL = res0.photoURL!=undefined&&res0.photoURL!=null&&res0.photoURL!='' ? res0.photoURL :'../assets/img/profile.png';
    })
  }

  handleFileInput(event: any) {
    if (event.target.files.length) {
      var fileTypes = ['jpg', 'jpeg', 'png'];  //acceptable file types
      var extension = event.target.files[0].name.split('.').pop().toLowerCase(),  //file extension from input file
      isSuccess = fileTypes.indexOf(extension) > -1;  //is extension in acceptable types
      if (isSuccess) { //yes
        // start file reader
        const reader = new FileReader();
        const angularCropper = this.angularCropper;
        reader.onload = (event) => {
          if(event.target?.result) {
            angularCropper.imageUrl = event.target.result;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      } else { //no
        alert('Selected file is not an image. Please select an image file.')
      }
    }
  }

  cropImage() {
    this.resultImage = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    this.photoURL = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    this.user.photoURL = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    let dwn: HTMLElement = document.querySelector('.download') as HTMLElement;
    dwn.setAttribute('href', this.resultImage);
  }

  uploadData() {
    return new Promise((resolve, reject) => {

      this.firestore.update(this.collectionName, this.user.id, { ...this.user }).then((data) => {
        alert('informacion actualizada')
        resolve(true);
      }).catch(function (error) {
        console.log(error);
        alert('No se puede guardar.. Intentalo mas tarde');
        resolve(false);
      });

    });
  }

  sendForm() {
    Swal.fire({
      icon: 'info',
      title: 'Espera por favor...',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: true,
      timer: 6e3
    });

    this.checkForm().then((data) => {
      if (data == null) {
        if (this.resultImage !== undefined && this.resultImage !== '') {
          this.savePhoto();
        }else{
          this.uploadData().then((data) => {
            this.edit = !this.edit;
            Swal.close();
            Swal.fire(
              'Listo!',
              'Guardado correctamente!',
              'success'
            )
          }).catch((e) => {
            this.edit = !this.edit;
          });
        }
      }
      else {
        alert(data);
      }
    }).catch(function (error) {
      console.log(error);
      alert(error);
    });
  }

  checkForm() {
    return new Promise((resolve, reject) => {
      let res = null;
      this.user.updated_at = new Date();

      if (this.user.username == null || this.user.username == '') { res = 'Nombre esta vacio' }
      if (this.user.direction == null || this.user.direction == '') { res = 'direccion esta vacio' }
      resolve(res);
    });
  }

  savePhoto() {
    this.dataURItoBlob(this.photoURL).then((blob: Blob) => {
      this.storageS.updatePhotoUser(this.collectionName, this.user.id, 1, blob).then((data) => {
        if (data) {
          this.user.photoURL = data['image'];
          this.user.image = data['file_path'];
          this.firestore.update(this.collectionName, this.user.id, { ...this.user }).then((data) => {
            this.edit = !this.edit;
            Swal.close();
            Swal.fire(
              'Listo!',
              'Guardado correctamente!',
              'success'
            )
          }).catch(function (error) {
            this.is_uploading = false;
            console.log(error);
            alert('No se puede guardar.. Intentalo mas tarde(1)');
          });
        } else {
          alert('No se puede guardar.. Intentalo mas tarde(2)');
        }
      }).catch((e) => {
        alert('No se puede guardar.. Intentalo mas tarde(3)');
      })
    }, (err) => {
      // Handle error
      console.log("Error dataURItoBlob(): ",err);
     });

  }

  dataURItoBlob(dataURI): Promise<Blob>  {
    return new Promise((resolve) => {
      const arr = dataURI.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
  
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
  
      // console.log("resolve dataURItoBlob: ", new Blob([u8arr], { type: mime }));
      resolve(new Blob([u8arr], {type: mime}));
    });
  }
}
