<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 footer-copyright">
            <p class="mb-0">Copyright {{year}} © <a href="https://yelou.app/" target="_blank">Yelou.app</a></p>
        </div>
        <div class="col-md-6">
            <!-- <p class="pull-right mb-0">Hand crafted & made with<i class="fa fa-heart"></i></p> -->
            <p class="pull-right mb-0">v23.09.05</p>
        </div>
    </div>
</div>